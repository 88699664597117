import { Component, OnInit } from '@angular/core';
import  *  as  data  from  '../flagdays.json';
import  *  as  months  from  '../months.json';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css']
})
export class FrontpageComponent implements OnInit {
  items = [];
  message = "";

  constructor() { }
  getCurrentMonthName(currentMonthNumber): string {
    for (const month in months.months) {
      if(currentMonthNumber === months.months[month]){
        return month;
      }  
    }
    return null;
  }
  checkIfDate(day, month, year): string {
    for (const element in data.flagdays) {
      if(month === data.flagdays[element].month && day === data.flagdays[element].day){
        if(!data.flagdays[element].year){
          return data.flagdays[element].info;
        }
        else if (year === data.flagdays[element].year){
          return data.flagdays[element].info;
        }
        else {
          return null;
        }
      }
    }
  }

  ngOnInit(): void {
    const date = new Date();
    
    let currentDay = date.getDate();
    const currentMonthNumber = date.getMonth()+1;
    let currentYear = date.getFullYear();
    let currentMonth = this.getCurrentMonthName(currentMonthNumber);
    
    /* currentDay = 31;
    currentMonth = "Mai";
    currentYear = 2020; */
    this.message = this.checkIfDate(currentDay, currentMonth, currentYear);
  }

}
