<section class="message" *ngIf="message, else noflagday">
   <p>Ja!</p>
      <p class="flagday">{{message}}.</p>
</section>

<ng-template #noflagday>
   <section class="message">
      <p>Nei</p>
   </section>
   
</ng-template>

